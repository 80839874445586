import { reduce } from 'lodash';
import * as yup from 'yup';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { format, parseISO } from 'date-fns';
import { PractitionerQuery } from '../../../../api/graphql';
import { Nullable } from '../../../../common/types';

export interface PractitionerEditForm {
  id: number;
  about: object;
  appointment_type: any;
  area: string;
  city: string;
  zip_code?: string;
  is_certified: boolean;
  is_trained: boolean;
  session_information: object;
  short_bio: string;
  status: string;
  display_name: string;
  email: string;
  first_name?: Nullable<string>;
  gender: any;
  last_name?: Nullable<string>;
  profile_status: string;
  location: string;
  skills: any;
  user_status: string;
  in_call_rate: number;
  out_call_rate: number;
  virtual_rate: number;
}

export const PractitionerEditSchema: yup.SchemaOf<PractitionerEditForm> =
  yup.object({
    id: yup.number().integer().required(),
    about: yup
      .object()
      .test({
        test: (value: any) => {
          if (value) {
            return value.getCurrentContent().getPlainText().length >= 50;
          }
          return false;
        },
        message: 'Body must be longer then 50 characters',
      })
      .required()
      .label('Body'),
    appointment_type: yup.array().of(yup.string().required()).nullable(),
    area: yup.string().required().label('Area'),
    city: yup.string().required().label('City'),
    zip_code: yup.string().label('city'),
    is_certified: yup.bool().required().label('Certified'),
    is_trained: yup.bool().required().label('Trained'),
    session_information: yup
      .object()
      .test({
        test: (value: any) => {
          if (value) {
            return value.getCurrentContent().getPlainText().length >= 50;
          }
          return false;
        },
        message: 'Body must be longer then 50 characters',
      })
      .required()
      .label('Session Information'),
    short_bio: yup.string().max(280).required().label('Short Bio'),
    display_name: yup.string().min(3).required().label('Display Name'),
    email: yup.string().email().required().label('Email'),
    first_name: yup
      .string()
      .matches(/.{3,}/, {
        excludeEmptyString: true,
        message: 'Must be 3 characters long',
      })
      .nullable()
      .label('First Name'),
    last_name: yup
      .string()
      .matches(/.{3,}/, {
        excludeEmptyString: true,
        message: 'Must be 3 characters long',
      })
      .nullable()
      .label('Last Name'),
    gender: yup
      .array()
      .of(
        yup
          .string()
          .oneOf([
            'Male',
            'Female',
            'Nonbinary',
            'Genderqueer',
            'Agender',
            'Prefer not to say',
          ])
      )
      .nullable()
      .label('Gender'),
    profile_status: yup
      .string()
      .oneOf(['Active', 'Inactive'])
      .required()
      .label('Profile Status'),
    location: yup.string().min(6).required().label('Location'),
    status: yup
      .string()
      .oneOf(['Verified', 'Unverified'])
      .required()
      .label('Status'),
    skills: yup.array().required().label('Skills'),
    user_status: yup
      .string()
      .oneOf(['Verified', 'Unverified'])
      .required()
      .label('User Status'),
    in_call_rate: yup.number().required().label('In Call Rate'),
    out_call_rate: yup.number().required().label('Out Call Rate'),
    virtual_rate: yup.number().required().label('Virtual Rate'),
  });

const setFormDefaults = (values: any) =>
  reduce<any, PractitionerEditForm>(
    values,
    (acc, val, key) => {
      if (key === '__typename') return acc;

      return {
        ...acc,
        [key]: val ?? '',
      };
    },
    {} as PractitionerEditForm
  );

// export const constructDefaultValues = (practitioner: PractitionerQuery) => {
//   if (practitioner.practitioner_by_pk) {
//     const { practitioner_by_pk } = practitioner;
//     console.log(practitioner_by_pk.about ?? '');
//     console.log(JSON.parse(practitioner_by_pk.about ?? ''));
//     console.log(
//       EditorState.createWithContent(
//         convertFromRaw(JSON.parse(practitioner_by_pk.about ?? ''))
//       )
//     );
//     console.log({ practitioner_by_pk });
//     return setFormDefaults({
//       id: practitioner_by_pk.id,
//       display_name: practitioner_by_pk.profile.owner.display_name,
//       email: practitioner_by_pk.profile.owner.email,
//       gender: practitioner_by_pk.profile.owner.gender,
//       location: practitioner_by_pk.profile.location,
//       first_name: practitioner_by_pk.profile.owner.first_name,
//       last_name: practitioner_by_pk.profile.owner.last_name,
//       status: practitioner_by_pk.status,
//       user_status: practitioner_by_pk.profile.owner.status,
//       profile_status: practitioner_by_pk.profile.status,
//       appointment_type: practitioner_by_pk.appointment_type,
//       area: practitioner_by_pk.area,
//       city: practitioner_by_pk.city,
//       short_bio: practitioner_by_pk.short_bio,
//       zip_code: practitioner_by_pk.zip_code,
//       is_certified: practitioner_by_pk.is_certified,
//       is_trained: practitioner_by_pk.is_trained,
//       in_call_rate: practitioner_by_pk.in_call_rate,
//       out_call_rate: practitioner_by_pk.out_call_rate,
//       virtual_rate: practitioner_by_pk.virtual_rate,
//       skills: practitioner_by_pk.skills.map((el) => el.skill),
//       about: EditorState.createWithContent(
//         convertFromRaw(JSON.parse(practitioner_by_pk.about ?? ''))
//       ),
//       session_information: EditorState.createWithContent(
//         convertFromRaw(JSON.parse(practitioner_by_pk.session_information ?? ''))
//       ),
//       created_at: format(
//         parseISO(practitioner_by_pk?.created_at),
//         'MM-dd-yyyy'
//       ),
//       updated_at: format(
//         parseISO(practitioner_by_pk?.updated_at),
//         'MM-dd-yyyy'
//       ),
//     });
//   }

//   return {};
// };

export const constructDefaultValues = (practitioner: PractitionerQuery) => {
  if (practitioner.practitioner_by_pk) {
    const { practitioner_by_pk } = practitioner;
    const {
      display_name,
      gender,
      location,
      appointment_type,
      area,
      city,
      short_bio,
      in_call_rate,
      out_call_rate,
      virtual_rate,
      about,
      session_information,
    } = practitioner_by_pk.fields_to_approve;

    const returnValue = setFormDefaults({
      id: practitioner_by_pk.id,
      display_name:
        display_name ?? practitioner_by_pk.profile.owner.display_name,
      email: practitioner_by_pk.profile.owner.email,
      gender: gender ?? practitioner_by_pk.profile.owner.gender,
      location: location ?? practitioner_by_pk.profile.location,
      first_name: practitioner_by_pk.profile.owner.first_name,
      last_name: practitioner_by_pk.profile.owner.last_name,
      status: practitioner_by_pk.status,
      user_status: practitioner_by_pk.profile.owner.status,
      profile_status: practitioner_by_pk.profile.status,
      appointment_type:
        appointment_type ?? practitioner_by_pk.appointment_type ?? [],
      area: area ?? practitioner_by_pk.area,
      city: city ?? practitioner_by_pk.city,
      short_bio: short_bio ?? practitioner_by_pk.short_bio,
      zip_code: practitioner_by_pk.zip_code,
      is_certified: practitioner_by_pk.is_certified,
      is_trained: practitioner_by_pk.is_trained,
      in_call_rate: in_call_rate ?? practitioner_by_pk.in_call_rate,
      out_call_rate: out_call_rate ?? practitioner_by_pk.out_call_rate,
      virtual_rate: virtual_rate ?? practitioner_by_pk.virtual_rate,
      skills: practitioner_by_pk.skills.map((el) => el.skill),
      about:
        about || practitioner_by_pk.about
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(about ?? practitioner_by_pk.about))
            )
          : EditorState.createEmpty(),
      session_information:
        session_information || practitioner_by_pk.session_information
          ? EditorState.createWithContent(
              convertFromRaw(
                JSON.parse(
                  session_information ?? practitioner_by_pk.session_information
                )
              )
            )
          : EditorState.createEmpty(),
      created_at: format(
        parseISO(practitioner_by_pk?.created_at),
        'MM-dd-yyyy'
      ),
      updated_at: format(
        parseISO(practitioner_by_pk?.updated_at),
        'MM-dd-yyyy'
      ),
    });
    return returnValue;
  }

  return {};
};

export const serializeFormData = (formData: PractitionerEditForm) => {
  const {
    id,
    in_call_rate,
    out_call_rate,
    virtual_rate,
    about,
    session_information,
    skills,
    ...values
  } = formData;
  return {
    ...values,
    id,
    about: JSON.stringify(
      convertToRaw((about as EditorState).getCurrentContent())
    ),
    session_information: JSON.stringify(
      convertToRaw((session_information as EditorState).getCurrentContent())
    ),
    skills: skills.map((skill: { id: number }) => ({
      practitioner_id: id,
      skill_id: skill.id,
    })),
    in_call_rate,
    out_call_rate,
    virtual_rate,
  };
};
