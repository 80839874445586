import React, { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  SxProps,
} from '@mui/material';

interface Props {
  name: string;
  label: string;
  control: Control<any, object>; // Try to remove this any from here
  sx?: SxProps;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  required?: boolean;
}

const RichTextField: React.FC<Props> = ({
  control,
  name,
  error,
  label,
  helperText,
  readOnly = false,
  sx,
  required = false,
}): ReactElement => {
  // Solve Video Embed Issue
  // https://github.com/jpuri/react-draft-wysiwyg/issues/155
  const embedVideoCallBack = (link: string) => {
    let parsedLink = link;
    if (link.indexOf('youtube') >= 0) {
      parsedLink = parsedLink.replace('watch?v=', 'embed/');
      parsedLink = parsedLink.replace('/watch/', '/embed/');
      parsedLink = parsedLink.replace('youtu.be/', 'youtube.com/embed/');
    }
    return parsedLink;
  };
  return (
    <FormControl sx={sx} error={error}>
      <InputLabel
        required={required}
        id={`field-label-${name}`}
        style={{ marginLeft: '-10px' }}
      >
        {label}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div style={{ marginTop: '50px' }}>
            <Editor
              toolbar={{
                embedded: { embedCallback: embedVideoCallBack },
                image: {
                  uploadCallback: (file: File) => {
                    return new Promise((resolve, reject) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => {
                        const dataURL = reader.result;
                        resolve({
                          data: { link: dataURL },
                        });
                      };
                      reader.onerror = (err) => {
                        reject(err);
                      };
                    });
                  },
                  alt: { present: true, mandatory: false },
                  previewImage: true,
                },
                // image: {
                //   // previewImage: true,
                //   uploadCallback: (file: File) =>
                //     new Promise((res) => {
                //       res({
                //         data: {
                //           link: URL.createObjectURL(file).toString().slice(5),
                //         },
                //       });
                //     }),
                //   // uploadCalback: (file: any) => {
                //   //   return new Promise((resolve, reject) => {
                //   //     const reader = new FileReader();
                //   //     reader.readAsDataURL(file);
                //   //     reader.onload = () => {
                //   //       const ldataURL = reader.result;
                //   //       // const truncatedDataURL =
                //   //       //   `${dataURL.toString().substring(10, 30)  }...`;
                //   //       // set the maximum length of the truncated string
                //   //       resolve({
                //   //         data: { link: dataURL },
                //   //         link: { url: dataURL },
                //   //       });
                //   //     };
                //   //     reader.onerror = (err) => {
                //   //       reject(err);
                //   //     };
                //   //   });
                //   // },
                //   // uploadEnabled: true,
                // },
              }}
              editorState={value}
              onEditorStateChange={onChange}
              readOnly={readOnly}
            />
          </div>
        )}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default RichTextField;
