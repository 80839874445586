import { convertToRaw, EditorState } from 'draft-js';
import * as yup from 'yup';

export interface PractitionerInsertForm {
  first_name: string;
  last_name: string;
  gender: any;
  email: string;
  display_name: string;
  location: string;
  zip_code?: string;
  city: string;
  is_certified: boolean;
  is_trained: boolean;
  appointment_type: any;
  short_bio: string;
  about: any;
  session_information: any;
  area: string;
  slug: string;
  skills: any;
  in_call_rate: number;
  out_call_rate: number;
  virtual_rate: number;
  booking_questions: any;
  booking_link?: string;
}

export const PractitionerInsertSchema: yup.SchemaOf<PractitionerInsertForm> =
  yup.object({
    about: yup
      .object()
      .test({
        test: (value: any) => {
          if (value) {
            return value.getCurrentContent().getPlainText().length >= 50;
          }
          return false;
        },
        message: 'Body must be longer then 50 characters',
      })
      .required()
      .label('Body'),
    appointment_type: yup.array().of(yup.string().required()).nullable(),
    area: yup.string().required().label('Area'),
    city: yup.string().required().label('City'),
    zip_code: yup.string().label('city'),
    is_certified: yup.bool().required().label('Certified'),
    is_trained: yup.bool().required().label('Trained'),
    session_information: yup
      .object()
      .test({
        test: (value: any) => {
          if (value) {
            return value.getCurrentContent().getPlainText().length >= 50;
          }
          return false;
        },
        message: 'Body must be longer then 50 characters',
      })
      .required()
      .label('Session Information'),
    short_bio: yup
      .string()
      .required()
      .min(25, 'Short bio must have at least 25 characters.')
      .max(280, 'Short bio must have less than 280 characters')
      .label('Short Bio'),
    display_name: yup.string().min(3).required().label('Display Name'),
    email: yup.string().email().required().label('Email'),
    skills: yup.array().required().label('Skills'),
    first_name: yup
      .string()
      .matches(/.{3,}/, {
        excludeEmptyString: true,
        message: 'Must be 3 characters long',
      })
      .required()
      .label('First Name'),
    last_name: yup
      .string()
      .matches(/.{3,}/, {
        excludeEmptyString: true,
        message: 'Must be 3 characters long',
      })
      .required()
      .label('Last Name'),
    gender: yup
      .array()
      .of(
        yup
          .string()
          .oneOf([
            'Male',
            'Female',
            'Nonbinary',
            'Genderqueer',
            'Agender',
            'Prefer not to say',
          ])
      )
      .nullable()
      .label('Gender'),
    location: yup
      .string()
      .min(6, 'Location must be longer than 6 characters')
      .required()
      .label('Location'),
    in_call_rate: yup.number().required().label('In Call Rate'),
    out_call_rate: yup.number().required().label('Out Call Rate'),
    virtual_rate: yup.number().required().label('Virtual Rate'),
    slug: yup.string().required().label('Slug'),
    booking_link: yup.string().url().label('Booking link'),
    booking_questions: yup.array().of(yup.object()),
  });

export const serializeFormData = (formData: PractitionerInsertForm) => {
  const {
    in_call_rate,
    out_call_rate,
    virtual_rate,
    about,
    session_information,
    skills,
    ...values
  } = formData;
  return {
    ...values,
    about: JSON.stringify(
      convertToRaw((about as EditorState).getCurrentContent())
    ),
    session_information: JSON.stringify(
      convertToRaw((about as EditorState).getCurrentContent())
    ),
    skills: skills.map((skill: { id: number }) => ({
      skill_id: skill.id,
    })),
    in_call_rate,
    out_call_rate,
    virtual_rate,
  };
};
