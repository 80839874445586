import React from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import * as yup from 'yup';
import { Box, Button, Backdrop, Typography } from '@mui/material';
import { useForm, useWatch, FieldError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import RichTextField from '../../../components/Inputs/RichTextField';
import Loader from '../../../components/Loader';
import TextField from '../../../components/Inputs/TextField';
import {
  useCreateBlogAdminMutation,
  useCreateBlogMutation,
} from '../../../api/graphql';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import useAuthenticated from '../../../hooks/useAuthenticated';

interface CreateBlogForm {
  title: string;
  body: object;
}

const BlogCreateSchema: yup.SchemaOf<CreateBlogForm> = yup.object({
  title: yup.string().min(4).required(),
  body: yup
    .object()
    .test({
      test: (value: any) => {
        if (value) {
          return value.getCurrentContent().getPlainText().length >= 140;
        }
        return false;
      },
      message: 'Body must be longer then 140 characters',
    })
    .required()
    .label('Body'),
});

const CreateBlog: React.FC = () => {
  const {
    control,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid },
  } = useForm<CreateBlogForm>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      body: EditorState.createEmpty(),
    },
    resolver: yupResolver(BlogCreateSchema),
  });

  const { user } = useAuthenticated();

  const navigate = useNavigate();
  const [createBlogRequest, { loading }] = useCreateBlogMutation({
    onCompleted: () => {
      SnackbarManager.addSnackbar({
        message: 'Blog entry successfully added',
        options: { variant: 'success' },
      });
      navigate('/dashboard/blogs');
    },
  });

  const [createBlogAdminRequest, { loading: loadingAdmin }] =
    useCreateBlogAdminMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Blog entry successfully added',
          options: { variant: 'success' },
        });
        navigate('/dashboard/blogs');
      },
    });

  const createBlogReq =
    user!.role === 'admin' ? createBlogAdminRequest : createBlogRequest;

  const { body } = useWatch({ control });
  const isEditorDirty =
    JSON.stringify(convertToRaw((body as EditorState).getCurrentContent())) !==
    JSON.stringify(EditorState.createEmpty());

  const { isDirty: isTitleDirty } = getFieldState('title');

  const createBlog = (values: CreateBlogForm) => {
    createBlogReq({
      variables: {
        ...values,
        body: JSON.stringify(
          convertToRaw((values.body as EditorState).getCurrentContent())
        ),
        ...(user?.role === 'admin' ? { author_id: user!.id } : {}),
      },
    });
  };

  return (
    <Box component="main">
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Create blog entry
      </Typography>
      <Box component="form" onSubmit={handleSubmit(createBlog)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="title"
            control={control}
            margin="normal"
            id="title"
            label="Title"
            error={!!errors.title}
            helperText={errors.title?.message}
            sx={{ flexGrow: '1' }}
            required
          />
          <RichTextField
            name="body"
            label="Body"
            error={!!errors.body}
            helperText={(errors.body as FieldError)?.message}
            control={control}
            required
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={!isValid || (!isEditorDirty && !isTitleDirty)}
            sx={{ flexGrow: 1 }}
          >
            Create
          </Button>
          {loading || loadingAdmin ? (
            <Backdrop open sx={{ zIndex: 100 }}>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateBlog;
