import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  FormControl,
  FormLabel,
} from '@mui/material';
import { useForm, useFormState, useWatch, FieldError } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { concat } from 'lodash';
import {
  usePractitionerQuery,
  useUpdatePractitionerByIdMutation,
  useDeletePractitionerByIdMutation,
  useApprovePractitionerProfileMutation,
  PractitionersDocument,
  PractitionerCountDocument,
  PractitionerDocument,
  useDeletePublicFileByKeyMutation,
} from '../../../../api/graphql';
import TextField from '../../../../components/Inputs/TextField';
import Loader from '../../../../components/Loader';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import SnackbarManager from '../../../../components/SnackbarManager/manager';
import RichTextField from '../../../../components/Inputs/RichTextField';
import MultiSelect from '../../../../components/Inputs/MultiSelect';
import { AppSelect as Select } from '../../../../components/Inputs/SelectField';
import {
  activeStatusOptions,
  genderOptions,
  verificationStatusOptions,
  listedStatusOptions,
} from '../../../../common/constants';
import Checkbox from '../../../../components/Inputs/Checkbox';
import {
  constructDefaultValues,
  PractitionerEditForm,
  PractitionerEditSchema,
  serializeFormData,
} from './form';
// import SkillsSelect from '../../../../components/SkillsSelect';
import CurrencyField from '../../../../components/Inputs/Currency';
import Link from '../../../../components/Link';
import Restricted from '../../../../components/restricted';
import useAuthenticated from '../../../../hooks/useAuthenticated';
import ImagesForApproval, {
  Image,
} from '../../../../components/ImagesForApproval/ImagesForApproval';

const constructModalBody = (practitioner?: any) =>
  `Are you sure you want to remove a practitioner with name ${practitioner?.name}`;

const EditPractitioner: React.FC = () => {
  const { id } = useParams();
  const [isRemovingItem, setIsRemovingItem] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const {
    data: practitioner,
    loading,
    refetch,
  } = usePractitionerQuery({
    variables: { id: parseInt(id!, 10) },
  });
  const {
    control,
    reset,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    setValue,
  } = useForm<PractitionerEditForm>({
    resolver: yupResolver(PractitionerEditSchema),
    mode: 'onChange',
    defaultValues: {
      appointment_type: [],
      skills: [],
      gender: [],
      session_information: EditorState.createEmpty(),
      about: EditorState.createEmpty(),
    },
  });

  const { isDirty } = useFormState({
    control,
    name: [
      'display_name',
      'email',
      'gender',
      'location',
      'first_name',
      'last_name',
      'status',
      'user_status',
      'profile_status',
      'appointment_type',
      'area',
      'city',
      'short_bio',
      'zip_code',
      'is_certified',
      'is_trained',
      'in_call_rate',
      'out_call_rate',
      'virtual_rate',
      'skills',
    ],
  });

  const isFieldsToApprove = useMemo(
    () =>
      practitioner && practitioner.practitioner_by_pk
        ? Object.keys(practitioner?.practitioner_by_pk?.fields_to_approve)
            .length !== 0
        : false,
    [practitioner]
  );
  const isUnverified = useMemo(
    () =>
      practitioner && practitioner.practitioner_by_pk
        ? practitioner?.practitioner_by_pk?.status === 'Unverified'
        : false,
    [practitioner]
  );
  const picturesToApprove = useMemo(
    () =>
      practitioner &&
      practitioner.practitioner_by_pk?.fields_to_approve.pictures
        ? practitioner?.practitioner_by_pk?.fields_to_approve.pictures
        : [],
    [practitioner]
  );
  const { about, session_information } = useWatch({ control });
  const isAboutDirty = useMemo(
    () =>
      practitioner && practitioner.practitioner_by_pk
        ? JSON.stringify(
            convertToRaw((about as EditorState).getCurrentContent())
          ) !== practitioner.practitioner_by_pk.about
        : false,

    [about, practitioner]
  );
  const isSessionInfoDirty = useMemo(
    () =>
      practitioner && practitioner.practitioner_by_pk
        ? JSON.stringify(
            convertToRaw(
              (session_information as EditorState).getCurrentContent()
            )
          ) !== practitioner.practitioner_by_pk.session_information
        : false,
    [practitioner, session_information]
  );

  const [updatePractitionerByIdRequest, { loading: updateLoading }] =
    useUpdatePractitionerByIdMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Practitioner successfully updated',
          options: { variant: 'success' },
        });
        refetch();
        reset(getValues());
      },
    });
  const [approvePractitionerProfile] = useApprovePractitionerProfileMutation({
    refetchQueries: [PractitionerDocument, 'practitioner'],
  });
  const [
    deletePractitionerRequest,
    { loading: deletePractitionerByIdLoading },
  ] = useDeletePractitionerByIdMutation({
    onCompleted: () => {
      setIsRemovingItem(false);
      SnackbarManager.addSnackbar({
        message: `Practitioner with ID ${practitioner?.practitioner_by_pk?.id} successfully deleted`,
        options: { variant: 'success' },
      });
      navigate('/dashboard/practitioners');
    },
    onError: () => {
      setIsRemovingItem(false);
    },
    refetchQueries: [
      PractitionersDocument,
      'practitioners',
      PractitionerCountDocument,
      'practitionerCount',
    ],
  });

  const { user } = useAuthenticated();
  const { role } = user!;

  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [deletePublicFileByKey] = useDeletePublicFileByKeyMutation();

  const updatePractitioner = (formData: PractitionerEditForm) => {
    const serializedData = serializeFormData(formData);
    imagesToDelete.forEach((key) => {
      deletePublicFileByKey({ variables: { key } });
    });

    const updatedPicturesToApprove = picturesToApprove.filter(
      (picture: Image) => !imagesToDelete.includes(picture.key)
    );

    updatePractitionerByIdRequest({
      variables: {
        ...serializedData,
        fields_to_approve: {},
        images: updatedPicturesToApprove,
      },
    });
  };

  const approveProfile = () => {
    handleSubmit(updatePractitioner)().then(() => {
      approvePractitionerProfile({
        variables: {
          id: practitioner!.practitioner_by_pk!.id,
        },
      });
    });
  };

  const [loadedDefault, setLoadedDefault] = useState<boolean>(false);

  useEffect(() => {
    if (practitioner) {
      const defaultValues = constructDefaultValues(practitioner);
      reset(defaultValues);
      setLoadedDefault(true);
    }
    // refetch();
  }, [practitioner, reset, loading, refetch]);

  useEffect(() => {
    if (loadedDefault && practitioner) {
      const fieldsToApprove =
        practitioner.practitioner_by_pk?.fields_to_approve;
      if (fieldsToApprove) {
        Object.keys(fieldsToApprove).forEach((key) => {
          if (key === 'about' || key === 'session_information') {
            setValue(
              key as keyof PractitionerEditForm,
              EditorState.createWithContent(
                convertFromRaw(
                  JSON.parse(fieldsToApprove[key as keyof PractitionerEditForm])
                )
              )
            );
          } else {
            setValue(key as keyof PractitionerEditForm, fieldsToApprove[key], {
              shouldDirty: true,
            });
          }
        });
      }
    }
    // don't want practitioner, want to wait till loadedDefault
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, setValue, loadedDefault]);

  const promptPractitionerDelete = () => {
    setIsRemovingItem(true);
  };

  const onDeleteApprove = () => {
    deletePractitionerRequest({
      variables: { id: practitioner!.practitioner_by_pk!.id },
    });
  };
  const onDeleteDeny = () => setIsRemovingItem(false);
  const onDeleteClose = () => setIsRemovingItem(false);

  if (loading) return <Loader />;

  return (
    <Box component="form" onSubmit={handleSubmit(updatePractitioner)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {(isFieldsToApprove || isUnverified) && (
          <Box
            sx={{ width: 'full', display: 'flex', justifyContent: 'center' }}
          >
            <Alert severity="info">
              {isFieldsToApprove
                ? 'This practitioner profile has changes made. Please review and approve.'
                : 'This practitioner profile is unverified. Their profile is not visible to the public.'}
            </Alert>
          </Box>
        )}
        <FormControl component="fieldset" disabled>
          <FormLabel component="legend">Profile</FormLabel>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              name="id"
              control={control}
              margin="normal"
              inputProps={{ readOnly: true }}
              id="id"
              label="ID"
              sx={{ flexGrow: '1' }}
            />
            <TextField
              name="created_at"
              control={control}
              margin="normal"
              id="crated_at"
              label="Created At"
              inputProps={{ readOnly: true }}
              sx={{ flexGrow: '1', marginLeft: '10px' }}
            />
            <TextField
              name="updated_at"
              control={control}
              margin="normal"
              id="updated_at"
              label="Updated At"
              inputProps={{ readOnly: true }}
              sx={{ flexGrow: '1', marginLeft: '10px' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TextField
              name="display_name"
              control={control}
              margin="normal"
              id="display_name"
              label="Display Name"
              error={!!errors.display_name}
              helperText={errors.display_name?.message}
              inputProps={{
                readOnly: role === 'editor' || role === 'editorPractitioner',
              }}
              sx={{ flexGrow: '1' }}
            />
            <TextField
              name="first_name"
              control={control}
              margin="normal"
              id="first_name"
              label="First name"
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
              inputProps={{
                readOnly: role === 'editor' || role === 'editorPractitioner',
              }}
              sx={{ flexGrow: '1', marginLeft: '10px' }}
            />
            <TextField
              name="last_name"
              control={control}
              margin="normal"
              id="last_name"
              label="Last Name"
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
              inputProps={{
                readOnly: role === 'editor' || role === 'editorPractitioner',
              }}
              sx={{ flexGrow: '1', marginLeft: '10px' }}
            />
          </Box>
          <MultiSelect
            name="gender"
            label="Gender"
            control={control}
            options={genderOptions}
            error={!!errors.gender}
            helperText={errors.gender?.message}
            readOnly={role === 'editor' || role === 'editorPractitioner'}
            sx={{ flexGrow: '1', marginTop: '10px' }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <Select<PractitionerEditForm, typeof verificationStatusOptions>
              name="status"
              label="Profile Visibility"
              control={control}
              options={listedStatusOptions}
              error={!!errors.status}
              helperText={errors.status?.message}
              readOnly={role === 'editor' || role === 'editorPractitioner'}
              sx={{ flexGrow: 1 }}
            />
            <Select<PractitionerEditForm, typeof activeStatusOptions>
              name="profile_status"
              label="Profile Activation Status"
              control={control}
              options={activeStatusOptions}
              error={!!errors.profile_status}
              helperText={errors.profile_status?.message}
              readOnly={role === 'editor' || role === 'editorPractitioner'}
              sx={{ flexGrow: 1, marginLeft: '10px' }}
            />
            <Select<PractitionerEditForm, typeof verificationStatusOptions>
              name="user_status"
              label="Email Verification"
              control={control}
              options={verificationStatusOptions}
              error={!!errors.user_status}
              helperText={errors.user_status?.message}
              readOnly={role === 'editor' || role === 'editorPractitioner'}
              sx={{ flexGrow: 1, marginLeft: '10px' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TextField
              name="email"
              control={control}
              margin="normal"
              id="email"
              label="Email"
              error={!!errors.email}
              helperText={errors.email?.message}
              inputProps={{
                readOnly: role === 'editor' || role === 'editorPractitioner',
              }}
              sx={{ flexGrow: '1' }}
            />
            <TextField
              name="location"
              control={control}
              margin="normal"
              id="location"
              label="Alternate City/State"
              error={!!errors.location}
              helperText={errors.location?.message}
              inputProps={{
                readOnly: role === 'editor' || role === 'editorPractitioner',
              }}
              sx={{ flexGrow: '1', marginLeft: '10px' }}
            />
            <TextField
              name="zip_code"
              control={control}
              margin="normal"
              id="zip_code"
              label="ZIP Code"
              error={!!errors.zip_code}
              helperText={errors.zip_code?.message}
              inputProps={{
                readOnly: role === 'editor' || role === 'editorPractitioner',
              }}
              sx={{ flexGrow: '1', marginLeft: '10px' }}
            />
            <TextField
              name="city"
              control={control}
              margin="normal"
              id="city"
              label="City"
              error={!!errors.city}
              helperText={errors.city?.message}
              inputProps={{
                readOnly: role === 'editor' || role === 'editorPractitioner',
              }}
              sx={{ flexGrow: '1', marginLeft: '10px' }}
            />
          </Box>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <FormControl component="fieldset" sx={{ marginTop: '10px' }}>
            <FormLabel component="legend">Cuddler Info</FormLabel>
            <TextField
              name="short_bio"
              control={control}
              error={!!errors.short_bio}
              helperText={(errors.short_bio as FieldError)?.message}
              margin="normal"
              id="short_bio"
              label="Short Bio"
              sx={{ flexGrow: '1' }}
              inputProps={{
                readOnly: role === 'editor' || role === 'editorPractitioner',
              }}
              multiline
            />
            <RichTextField
              name="about"
              label="About"
              error={!!errors.about}
              helperText={(errors.about as FieldError)?.message}
              readOnly={role === 'editor' || role === 'editorPractitioner'}
              control={control}
            />
            <RichTextField
              name="session_information"
              label="Session Information"
              error={!!errors.session_information}
              helperText={(errors.session_information as FieldError)?.message}
              readOnly={role === 'editor' || role === 'editorPractitioner'}
              control={control}
            />
            <MultiSelect
              name="appointment_type"
              label="Appointment Type"
              control={control}
              options={['In Call', 'Out Call', 'Virtual']}
              error={!!errors.appointment_type}
              helperText={errors.appointment_type?.message}
              readOnly={role === 'editor' || role === 'editorPractitioner'}
              sx={{ marginTop: 1 }}
            />
            <TextField
              name="area"
              control={control}
              margin="normal"
              id="area"
              label="Area"
              error={!!errors.area}
              helperText={errors.area?.message}
              inputProps={{
                readOnly: role === 'editor' || role === 'editorPractitioner',
              }}
              sx={{ flexGrow: '1' }}
            />
          </FormControl>
          {/* <SkillsSelect<PractitionerEditForm>
            id="skills"
            name="skills"
            label="Skills"
            control={control}
            readOnly={role === 'editor' || role === 'editorPractitioner'}
            fullWidth
          /> */}
          <FormControl component="fieldset" sx={{ marginTop: '10px' }}>
            <FormLabel component="legend">Rates</FormLabel>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              <CurrencyField<PractitionerEditForm>
                name="in_call_rate"
                control={control}
                sx={{ flexGrow: 1 }}
                inputProps={{
                  readOnly: role === 'editor' || role === 'editorPractitioner',
                }}
                label="In Call Rate"
              />
              <CurrencyField<PractitionerEditForm>
                name="out_call_rate"
                control={control}
                sx={{ flexGrow: 1, marginLeft: '10px' }}
                inputProps={{
                  readOnly: role === 'editor' || role === 'editorPractitioner',
                }}
                label="Out Call Rate"
              />
              <CurrencyField<PractitionerEditForm>
                name="virtual_rate"
                control={control}
                sx={{ flexGrow: 1, marginLeft: '10px' }}
                inputProps={{
                  readOnly: role === 'editor' || role === 'editorPractitioner',
                }}
                label="Virtual Rate"
              />
            </Box>
          </FormControl>
          <FormControl sx={{ marginTop: '10px' }}>
            <FormLabel component="legend">Qualifications</FormLabel>
            <Box sx={{ display: 'flex' }}>
              <Checkbox
                name="is_certified"
                label="Certified"
                control={control}
                disabled={role === 'editor' || role === 'editorPractitioner'}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 },
                }}
              />
              <Checkbox
                name="is_trained"
                label="Trained"
                control={control}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 28 },
                }}
                disabled={role === 'editor' || role === 'editorPractitioner'}
              />
            </Box>
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <ImagesForApproval
          images={concat(
            practitioner?.practitioner_by_pk?.pictures ?? [],
            practitioner?.practitioner_by_pk?.fields_to_approve.pictures ?? []
          )}
          setImagesToDelete={setImagesToDelete}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
          paddingBottom: '10px',
        }}
      >
        <Restricted to={['admin']}>
          <Button
            // type="submit"
            variant="contained"
            onClick={approveProfile}
            disabled={
              !isValid ||
              !(
                isDirty ||
                isAboutDirty ||
                isSessionInfoDirty ||
                Boolean(picturesToApprove.length) ||
                Boolean(imagesToDelete.length) ||
                Boolean(
                  Object.keys(
                    practitioner?.practitioner_by_pk?.fields_to_approve
                  ).length !== 0
                )
              )
            }
            sx={{ flexGrow: 1 }}
          >
            {isFieldsToApprove ? 'Approve/Update' : 'Update'}
          </Button>
          {!isValid && (
            <Button
              type="submit"
              variant="contained"
              sx={{ flexGrow: 0.25, marginLeft: '10px' }}
            >
              Show All Errors
            </Button>
          )}
          <Button
            variant="contained"
            color="error"
            onClick={promptPractitionerDelete}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          >
            Delete
          </Button>
        </Restricted>
        <Restricted to={['admin', 'editor', 'editorPractitioner']}>
          {practitioner?.practitioner_by_pk?.status !== 'Verified' ? (
            <>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={approveProfile}
                sx={{ flexGrow: 1, marginLeft: '10px' }}
              >
                Approve
              </Button> */}
              <Link
                to={`/dashboard/practitioners/review/${practitioner?.practitioner_by_pk?.profile?.id}`}
                style={{ flexGrow: 1, marginLeft: '10px' }}
              >
                <Button variant="contained" color="primary" fullWidth>
                  Review
                </Button>
              </Link>
            </>
          ) : null}
        </Restricted>
        {updateLoading ? (
          <Backdrop open sx={{ zIndex: 100 }}>
            <Loader />
          </Backdrop>
        ) : null}
      </Box>
      <ConfirmationModal
        open={isRemovingItem}
        onApprove={onDeleteApprove}
        onDeny={onDeleteDeny}
        onClose={onDeleteClose}
        showLoader={deletePractitionerByIdLoading}
        content={{
          body: constructModalBody(practitioner!.practitioner_by_pk),
          agreeText: 'Delete',
          denyText: 'Cancel',
          title: 'Remove Practitioner',
        }}
      />
    </Box>
  );
};

export default EditPractitioner;
