import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Backdrop, Box, Button } from '@mui/material';
import { FieldError, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { format, parseISO } from 'date-fns';
import {
  AnnouncementCountDocument,
  AnnouncementDocument,
  useAnnouncementQuery,
  useDeleteAnnouncementByIdMutation,
  useUpdateAnnouncementByIdMutation,
} from '../../../api/graphql';
import TextField from '../../../components/Inputs/TextField';
import Loader from '../../../components/Loader';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SnackbarManager from '../../../components/SnackbarManager/manager';
import RichTextField from '../../../components/Inputs/RichTextField';
import {
  AppSelect as SelectField,
  AppSelectOption,
} from '../../../components/Inputs/SelectField';

const audienceSelectOptions: AppSelectOption[] = [
  {
    label: 'Practitioner',
    value: 'practitioner',
  },
  {
    label: 'Client',
    value: 'client',
  },
  {
    label: 'All',
    value: 'all',
  },
];

interface AnnouncementEditForm {
  id: number;
  title: string;
  message: object;
  audience: string;
}

const AnnouncementEditSchema: yup.SchemaOf<AnnouncementEditForm> = yup.object({
  id: yup.number().integer().required(),
  title: yup.string().min(4).required().label('Title'),
  audience: yup.string().oneOf(['practitioner', 'client', 'all']).required(),
  message: yup.object().required().label('Body'),
});

const constructModalBody = (announcement?: any) =>
  `Are you sure you want to remove the announcement with title ${announcement?.title}`;

const EditAnnouncement: React.FC = () => {
  const { id } = useParams();
  const [isRemovingItem, setIsRemovingItem] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { data: announcement, loading } = useAnnouncementQuery({
    variables: { id: parseInt(id!, 10) },
  });
  const {
    control,
    reset,
    formState: { errors, isValid },
    handleSubmit,
    getValues,
    getFieldState,
  } = useForm<AnnouncementEditForm>({
    resolver: yupResolver(AnnouncementEditSchema),
    mode: 'onChange',
    defaultValues: {
      title: '',
      message: EditorState.createEmpty(),
      audience: 'practitioner',
    },
  });
  const { message } = useWatch({ control });
  const isEditorDirty = message
    ? JSON.stringify(
        convertToRaw((message as EditorState).getCurrentContent())
      ) !== announcement?.announcement_by_pk?.message
    : false;

  const { isDirty: isTitleDirty } = getFieldState('title');
  const { isDirty: isAudienceDirty } = getFieldState('audience');

  const [updateAnnouncementByIdRequest, { loading: updateLoading }] =
    useUpdateAnnouncementByIdMutation({
      onCompleted: () => {
        SnackbarManager.addSnackbar({
          message: 'Announcement successfully updated',
          options: { variant: 'success' },
        });
        reset(getValues());
      },
    });
  const [
    deleteAnnouncementRequest,
    { loading: deleteAnnouncementByIdLoading },
  ] = useDeleteAnnouncementByIdMutation({
    onCompleted: () => {
      setIsRemovingItem(false);
      SnackbarManager.addSnackbar({
        message: `Announcement with ID ${announcement?.announcement_by_pk?.id} successfully deleted`,
        options: { variant: 'success' },
      });
      navigate('/dashboard/announcements');
    },
    onError: () => {
      setIsRemovingItem(false);
    },
    refetchQueries: [
      AnnouncementDocument,
      'announcements',
      AnnouncementCountDocument,
      'announcementCount',
    ],
  });

  useEffect(() => {
    if (announcement && announcement.announcement_by_pk) {
      const { announcement_by_pk } = announcement;
      const parsed = {
        ...announcement_by_pk,
        message: EditorState.createWithContent(
          convertFromRaw(JSON.parse(announcement_by_pk!.message))
        ),
        created_at: format(
          parseISO(announcement_by_pk?.created_at),
          'MM-dd-yyyy'
        ),
        updated_at: format(
          parseISO(announcement_by_pk?.updated_at),
          'MM-dd-yyyy'
        ),
      };
      reset(parsed);
    }
  }, [announcement?.announcement_by_pk, loading, reset]);

  const updateAnnouncement = (formData: AnnouncementEditForm) => {
    updateAnnouncementByIdRequest({
      variables: {
        ...formData,
        message: JSON.stringify(
          convertToRaw((formData.message as EditorState).getCurrentContent())
        ),
      },
    });
  };

  const promptAnnouncementDelete = () => {
    setIsRemovingItem(true);
  };

  const onDeleteApprove = () => {
    deleteAnnouncementRequest({
      variables: { id: announcement!.announcement_by_pk!.id },
    });
  };
  const onDeleteDeny = () => setIsRemovingItem(false);
  const onDeleteClose = () => setIsRemovingItem(false);

  if (loading) return <Loader />;

  return (
    <Box component="main">
      <Box component="form" onSubmit={handleSubmit(updateAnnouncement)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            name="id"
            control={control}
            margin="normal"
            inputProps={{ readOnly: true }}
            id="id"
            label="ID"
            sx={{ flexGrow: '1' }}
          />
          <TextField
            name="created_at"
            control={control}
            margin="normal"
            id="crated_at"
            label="Created At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
          <TextField
            name="updated_at"
            control={control}
            margin="normal"
            id="updated_at"
            label="Updated At"
            inputProps={{ readOnly: true }}
            sx={{ flexGrow: '1', marginLeft: '10px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <TextField
            name="title"
            control={control}
            margin="normal"
            id="title"
            label="Title"
            error={!!errors.title}
            helperText={errors.title?.message}
            sx={{ flexGrow: '1' }}
            required
          />
          <RichTextField
            name="message"
            label="Message"
            error={!!errors.message}
            helperText={(errors.message as FieldError)?.message}
            control={control}
            required
          />
          <SelectField<AnnouncementEditForm, typeof audienceSelectOptions>
            name="audience"
            label="Audience"
            control={control}
            error={!!errors.audience}
            options={audienceSelectOptions}
            helperText={errors.audience?.message}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="submit"
            variant="contained"
            disabled={
              !isValid || (!isEditorDirty && !isTitleDirty && !isAudienceDirty)
            }
            sx={{ flexGrow: 1 }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={promptAnnouncementDelete}
            sx={{ flexGrow: 1, marginLeft: '10px' }}
          >
            Delete
          </Button>
          {updateLoading ? (
            <Backdrop open sx={{ zIndex: 100 }}>
              <Loader />
            </Backdrop>
          ) : null}
        </Box>
        <ConfirmationModal
          open={isRemovingItem}
          onApprove={onDeleteApprove}
          onDeny={onDeleteDeny}
          onClose={onDeleteClose}
          showLoader={deleteAnnouncementByIdLoading}
          content={{
            body: constructModalBody(announcement!.announcement_by_pk),
            agreeText: 'Delete',
            denyText: 'Cancel',
            title: 'Remove Announcement',
          }}
        />
      </Box>
    </Box>
  );
};

export default EditAnnouncement;
